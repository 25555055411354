(function () {
  const tickerItems = Array.from(document.querySelectorAll('.ticker__item'));
  let active = 0;
  let isPlaying = true;
  let prev = tickerItems.length - 1;
  const activeClass = 'ticker__item--active';
  const leaveClass = 'ticker__item--leaving';
  const initTicker = () => {
    if (isPlaying) {
      if (active === tickerItems.length - 1) {
        active = 0;
        prev = tickerItems.length - 1;
      } else {
        active++;
        prev = active - 1;
      }
      tickerItems[prev].classList.remove(activeClass);
      tickerItems[prev].classList.add(leaveClass);
      tickerItems[active].classList.remove(leaveClass);
      tickerItems[active].classList.add(activeClass);
    }
  };
  // add active class to first ticker item if it exists
  tickerItems.length && tickerItems[0].classList.add(activeClass);

  // ... and change active ticker item every 4 sec if there is more than one item
  tickerItems.length > 1 && setInterval(initTicker, 4000);

  // Pause ticker when invisible
  const tickerObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      entry.isIntersecting ? (isPlaying = true) : (isPlaying = false);
    });
  });
  tickerObserver.observe(document.querySelector('.ticker'));
})();
